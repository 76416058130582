import Vue from 'vue'
import App from './App.vue'
import Router from "vue-router";

// 引入 Vue Router 并创建路由对象
Vue.use(Router);

const routes = [
    {
        path: '/',
        redirect: '/index'  // 重定向到 /home 路径
    },
    {
        path: '/',
        name: "index",
        component: () => import('@/components/index.vue'),
        children: [
            {
                path: 'index',
                component: () => import('@/components/index/index'),
            }, {
                path: 'commIndex',
                component: () => import('@/components/commIndex/commonProblem'),
            }, {
                path: 'virus',
                component: () => import('@/components/virus/virus'),
            },{
                path: 'down',
                component: () => import('@/components/down/down'),
            },{
                path: 'mingren',
                component: () => import('@/components/mingren/mingren'),
            },{
                path: 'shenglv',
                component: () => import('@/components/shenglv/shenglv'),
            },{
                path: 'zhenmozuo',
                component: () => import('@/components/zhenmozuo/zhenmozuo'),
            },{
                path: 'tongxing',
                component: () => import('@/components/tongxing/tongxing'),
            },{
                path: 'titleText',
                component: () => import('@/components/titleText/titleText'),
            },{
                path: 'ggClassroom',
                component: () => import('@/components/Classification/ggClassroom'),
            }
        ]
    },
];

// 创建路由对象并且传入规则
const router = new Router({
    routes,
    mode: 'history',
});

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
